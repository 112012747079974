<template>
  <div class="container">
    <div class="mt-4">

      <h4 class="text-center">Список жалоб</h4>

      <!-- Table Start -->
      <DataTable :value="complaintsInfos" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10, 20, 50, 100, 200]"
                 filterDisplay="menu"
                 :globalFilterFields="['user.username','user.studentData.last_name','user.studentData.first_name']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
          </div>
        </template>
        <template #empty>
          Студент не найден
        </template>
        <template #loading>
          Идет поиск студентов. Пожалуйста подождите.
        </template>

        <Column field="user.username" header="Баркод"></Column>
        <Column field="title" header="Тема"></Column>
        <Column field="complaintType.name" header="Тип"></Column>
        <Column field="complaintsWorkStatus.name" header="Статус"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.user?.studentData?.last_name}} {{data.user?.studentData?.first_name}}
          </template>
        </Column>

        <Column header="Информация">
          <template #body="{data}">
            <Button icon="pi pi-info-circle" @click="viewComplaintInfo(data.id)"/>
          </template>
        </Column>


      </DataTable>
      <!-- Table End -->


      <Dialog v-model:visible="complaintInfoDisplay" :style="{width: '98%', maxWidth: '800px'}" :modal="true">
        <template #header>
          <h5>Информация о жалобе</h5>
        </template>
        <div class="mt-4" style="height: 400px">

          <div class="row mt-4">
            <div class="col-md-2">
              <b>Тема</b>
            </div>
            <div class="col-md-10">
              {{filteredComplaintInfos.title}}
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-2">
              <b>Описание</b>
            </div>
            <div class="col-md-10">
              {{filteredComplaintInfos.description}}
            </div>
          </div>

          <div class="row mt-4" v-if="filteredComplaintInfos?.complaintsFiles.length">
            <div class="col-md-2">
              <b>Файлы</b>
            </div>
            <div class="col-md-10">
              <div class="mb-1" v-for="(file, fileIndex) in filteredComplaintInfos?.complaintsFiles" :key="fileIndex">
                <a :href="`https://back.uib.kz/uploads/${file?.file}`" target="_blank">
                  Файл {{fileIndex+1}}
                </a>
              </div>
            </div>
          </div>

        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeComplaintInfoDialog"/>
        </template>
      </Dialog>


    </div>
  </div>
</template>

<script>
  import {FilterMatchMode, FilterOperator} from 'primevue/api'
  import httpClient from "@/services/http.service"

  export default {
    name: "StudentsList",

    data() {
      return {
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'user.username': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'user.studentData.last_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'user.studentData.first_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        complaintsInfos: [],
        loading: true,
        complaintInfoDisplay: false,
        filteredComplaintInfos: null
      }
    },
    computed: {},
    methods: {
      async GET_COMPLAINTS() {
        try {
          const {status, data} = await httpClient.get(`complaints/complaints/index`)
          if (status === 200) {
            this.complaintsInfos = data
          }
        } catch (e) {
          console.log(e.response)
        }
      },
      viewComplaintInfo(complaintId) {
        console.log(complaintId, 'complaintId')
        this.complaintInfoDisplay = true
        this.filteredComplaintInfos = this.complaintsInfos.find(i => i.id == complaintId)
        // console.log(this.filteredComplaintInfos, 'filteredComplaintInfos')
      },
      closeComplaintInfoDialog() {
        this.complaintInfoDisplay = false
      }
    },
    async mounted() {
      await this.GET_COMPLAINTS()
    }
  }
</script>

<style scoped>

</style>